import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { checkIsFullscreen, destroyVideo, requestFullscreen, useFullScreenChangeEvent } from '../videoUtils';
import { GenericVideoPlayer, VideoPlayerProps } from './VideoPlayer.types';

function onContextMenu(event: React.MouseEvent<HTMLVideoElement>) {
  event.preventDefault();
  event.stopPropagation();
  return false;
}

class HTMlVideoPlayerInstance implements GenericVideoPlayer {
  constructor(public videoElement: HTMLVideoElement) {}

  get currentTime() {
    return this.videoElement.currentTime;
  }

  set currentTime(time: number) {
    this.videoElement.currentTime = time;
  }

  get duration() {
    return this.videoElement.duration;
  }

  enterFullscreen() {
    return requestFullscreen(this.videoElement);
  }
}

export default function HTMLVideoPlayer({
  title,
  src,
  subtitles,
  loop,
  videoRef,
  onEnded,
  onPause,
  onVideoLoaded,
  onDurationChange,
  onFullscreenChange,
}: VideoPlayerProps) {
  const _videoRef = useRef<HTMLVideoElement>(null);

  useFullScreenChangeEvent(_videoRef.current, () => {
    const isFullscreen = checkIsFullscreen();
    onFullscreenChange?.(isFullscreen);
  });

  // (re)assigns the videoRef to the forwarded ref
  useEffect(() => {
    // console.log('_videoRef.current or videoRef prop changed', _videoRef?.current, videoRef);

    if (videoRef && _videoRef?.current) videoRef.current = new HTMlVideoPlayerInstance(_videoRef.current);
  }, [_videoRef?.current, videoRef]);

  // Destroy video on unmount
  useEffect(
    () => () => {
      if (_videoRef.current) {
        destroyVideo(_videoRef.current);
      }
    },
    [],
  );

  return (
    <VideoViewer
      title={title}
      crossOrigin="anonymous"
      ref={_videoRef}
      autoPlay
      controls
      src={src}
      onPause={({ currentTarget }) => onPause?.(new HTMlVideoPlayerInstance(currentTarget))}
      onEnded={onEnded}
      onContextMenu={onContextMenu}
      onLoadedMetadata={({ currentTarget }) => onVideoLoaded?.(new HTMlVideoPlayerInstance(currentTarget))}
      onDurationChange={({ currentTarget }) => onDurationChange?.(new HTMlVideoPlayerInstance(currentTarget))}
      controlsList="nodownload"
      playsInline
      loop={loop}
    >
      {subtitles?.map((sub) => (
        <track key={sub.src} kind="subtitles" srcLang={sub.languageCode} lang={sub.languageCode} src={sub.src} label={sub.label} />
      ))}
    </VideoViewer>
  );
}

export const VideoViewer = styled.video`
  width: 100%;
  aspect-ratio: 16/9;
  max-height: 600px;
  max-width: 1067px;
`;
